
.top-action-bar {

    width:100%;

    .tools {
        width:100%;
        padding:0;
    }

    h1 {
    }

    .subtitle {
        margin:0;
        padding:0;
        font-size:0.6rem;
    }

}