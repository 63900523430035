
.table {

    margin-bottom:0;

    .overflow-wrapped {

        overflow-wrap: anywhere;

    }

    th {
        white-space:nowrap;
    }


}